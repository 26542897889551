<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Vui lòng chọn" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>Chính sách bảo mật</h2>
		<p>
			Giới thiệu<br/>
			Chúng tôi hiểu tầm quan trọng của thông tin cá nhân đối với bạn và sẽ tuân thủ nghiêm ngặt các yêu cầu của pháp
			luật và quy định để thực hiện các biện pháp bảo vệ an ninh tương ứng, cam kết giữ cho thông tin cá nhân của bạn an
			toàn và có thể kiểm soát được. Sử dụng PIX Technology PTE
			Trước khi dịch vụ Nền tảng UniLive thuộc Công ty TNHH, bạn được yêu cầu đọc kỹ và hiểu đầy đủ Chính sách bảo mật
			chức năng thiết yếu này (sau đây gọi là "Chính sách bảo mật này") để bạn có thể đưa ra lựa chọn sử dụng thích hợp.
			br />
		</p>
		<p>
			Chính sách bảo mật này chỉ áp dụng cho các dịch vụ của Phần mềm UniLive, bao gồm các trang web, trang web, khách
			hàng và ứng dụng và các phương tiện truyền tải dịch vụ mới khi công nghệ phát triển.
		</p>
		<p>
			Nếu bạn có bất kỳ câu hỏi nào liên quan đến Chính sách bảo mật này, bạn có thể liên hệ với chúng tôi theo cách
			được nêu trong Chính sách bảo mật này "Liên hệ với chúng tôi như thế nào", và chúng tôi sẽ sẵn lòng trả lời bạn.
			Chúng tôi cũng sẽ sửa đổi Chính sách bảo mật này theo thời gian và thông báo cho bạn theo cách thích hợp dựa trên
			những thay đổi về luật pháp và phát triển kinh doanh.
		</p>
		<p>
			Nếu bạn là trẻ vị thành niên dưới 14 tuổi hoặc không có năng lực hành vi dân sự đầy đủ do tình trạng sức khỏe trí
			tuệ, tâm thần, vui lòng đọc và đồng ý với Chính sách bảo mật này dưới sự hướng dẫn và đi cùng của người giám hộ
			hợp pháp của bạn; Nếu bạn là trẻ vị thành niên dưới 14 tuổi, hãy yêu cầu người giám hộ hợp pháp của bạn đọc và
			đồng ý với Chính sách bảo mật này cho bạn. Yêu cầu bạn và người giám hộ hợp pháp của bạn đặc biệt chú ý đến nội
			dung bảo vệ trẻ vị thành niên.
		</p>
		<h3>Chính sách bảo mật này sẽ giúp bạn hiểu những điều sau đây</h3>
		<p>Thu thập và sử dụng thông tin cá nhân</p>
		<p>Bảo vệ và lưu trữ thông tin cá nhân</p>
		<p>Quyền của bạn</p>
		<p>Bảo vệ trẻ vị thành niên</p>
		<p>7 Cách liên hệ với chúng tôi</p>
		<h3>I. Thu thập và sử dụng thông tin cá nhân</h3>
		<p>
			UniLive cung cấp dịch vụ tìm kiếm video, phát video ngắn không quá một khoảng thời gian nhất định và nếu bạn không
			đồng ý với Chính sách bảo mật này, bạn có thể nhấp vào "Không đồng ý" để vào Chế độ khách truy cập.
		</p>
		<p>
			Chúng tôi cũng cung cấp cho bạn nhiều sản phẩm và/hoặc dịch vụ phong phú hơn. Để thực hiện các tính năng và/hoặc
			dịch vụ mà bạn chọn sử dụng và tuân thủ các yêu cầu của pháp luật và quy định, chúng tôi cần thu thập, sử dụng
			thông tin cá nhân tương ứng của bạn và/hoặc bật các quyền cần thiết tương ứng. Nếu bạn từ chối, nó có thể dẫn đến
			việc bạn không thể sử dụng các tính năng và/hoặc dịch vụ, nhưng không ảnh hưởng đến việc sử dụng bình thường các
			tính năng và/hoặc dịch vụ không liên quan khác. Bạn có thể quản lý thông tin cá nhân và quyền của mình thông qua
			Phần IV của Chính sách bảo mật này, "Quyền của bạn".
		</p>
		<p>
			Nếu bạn đồng ý với Chính sách bảo mật tính năng thiết yếu này, chúng tôi chỉ xử lý thông tin cá nhân cần thiết khi
			bạn đồng ý sử dụng các tính năng thiết yếu của UniLive. Nếu bạn sử dụng các tiện ích mở rộng khác của UniLive,
			chúng tôi sẽ yêu cầu sự đồng ý của bạn khi bạn sử dụng các tiện ích mở rộng cụ thể.
		</p>
		<p>
			Trong một số trường hợp, chúng tôi cũng giải thích cho bạn mục đích, phạm vi và cách sử dụng thông tin được thu
			thập thông qua thông báo tức thời (cửa sổ bật lên, lời nhắc trang), hướng dẫn cập nhật chức năng. Mô tả này tạo
			thành một phần của Chính sách bảo mật này và có hiệu lực tương đương với Chính sách bảo mật này.
		</p>
		<p>
			Thông thường, thông tin cá nhân của bạn hoặc yêu cầu quyền liên quan được thu thập và sử dụng để thực hiện các
			tính năng và/hoặc dịch vụ sau đây của Nền tảng UniLive:
		</p>
		<p>
			1. Dịch vụ tài khoản
		</p>
		<p>
			Để sử dụng các tính năng và/hoặc dịch vụ phong phú của chúng tôi (bình luận, lượt thích, bộ sưu tập, đăng video),
			bạn cần cung cấp thông tin/ủy quyền phù hợp để đăng ký và đăng nhập vào Nền tảng UniLive.
		</p>
		<p>
			1.1 Email: Nếu bạn sử dụng email để đăng nhập vào các sản phẩm và dịch vụ UniLive, bạn cần cung cấp địa chỉ email
			và mật khẩu làm thông tin cần thiết.
		</p>
		<p>
			2. Duyệt nội dung, chơi và tìm kiếm
		</p>
		<p>
			2.1 Duyệt và chơi
			Khi bạn duyệt và phát nội dung trên nền tảng UniLive, chúng tôi sẽ ghi lại thông tin thiết bị của bạn (OAID,
			AndroidID, IDFA) và lịch sử truy cập Internet cá nhân (duyệt, nhấp, chia sẻ, sưu tầm) để cung cấp cho bạn trải
			nghiệm sử dụng liên tục và nhất quán, đảm bảo chất lượng dịch vụ.
		</p>
		<p>
			2.1.1 Duyệt web liên quan đến vị trí: Khi bạn sử dụng dịch vụ cùng thành phố, chúng tôi sẽ yêu cầu bạn cho phép
			bật vị trí để thu thập và sử dụng thông tin vị trí địa lý chính xác của bạn. Nếu bạn không cho phép thông tin vị
			trí chính xác có thể ảnh hưởng đến độ chính xác của vị trí, chúng tôi sẽ dựa vào thông tin vị trí thô (điểm truy
			cập WLAN (SSID, BSSID), Bluetooth và trạm gốc, thông tin cảm biến, thông tin địa chỉ IP, được gọi chung là thông
			tin vị trí địa lý chính xác) và thông tin vị trí được cung cấp trong các tính năng và/hoặc dịch vụ có liên quan
			(vị trí tự điền trong dữ liệu tài khoản của bạn, liên kết vị trí được thêm vào nội dung tải lên của bạn) để đánh
			giá vị trí gần đúng của bạn để hiển thị cho bạn nội dung và/hoặc dịch vụ giá trị gia tăng liên quan đến thông tin
			vị trí trên khi bạn sử dụng trang chủ, chọn lọc, khám phá, theo dõi các tính năng.
		</p>
		<p>
			2.1.2 Phát lại màn hình ngang: Khi bạn sử dụng chế độ màn hình ngang, chúng ta cần gọi cảm biến gia tốc, trọng
			lực, hướng của bạn, phát hiện sự thay đổi hướng màn hình thiết bị thông qua thông tin cảm biến, thực hiện chuyển
			đổi màn hình ngang.
		</p>
		<p>
			2.2 Tìm kiếm
		</p>
		<p>
			Khi bạn sử dụng chức năng tìm kiếm, chúng tôi thu thập thông tin thiết bị của bạn (OAID, AndroidID, IDFA), hướng
			dẫn tìm kiếm (văn bản, giọng nói hoặc hình ảnh) và thông tin nhật ký. Để cung cấp dịch vụ tìm kiếm hiệu quả, thông
			tin từ khóa tạm thời được lưu trữ trong thiết bị địa phương của bạn, hiển thị cho phù hợp khi bạn nhấp vào biểu
			tượng tìm kiếm và bạn cũng có thể chọn xóa toàn bộ hoặc một phần lịch sử tìm kiếm đó.
			Khi bạn nhận dạng mã 2D, tìm kiếm hàng hóa, hoặc tìm kiếm hình ảnh, chúng tôi sẽ xin phép bạn mở
			máy ảnh, quyền lợi album, thuận tiện cho bạn chụp ảnh hoặc đăng ảnh lên album để thực hiện tìm
			kiếm. Khi sử dụng Swipe, chúng tôi sẽ gọi cảm biến ánh sáng của bạn để điều chỉnh độ sáng màn hình, cảm biến gia
			tốc tuyến tính của bạn để phát hiện hướng và thông tin góc của thiết bị, giúp bạn hoàn thành việc xác định những
			gì quét một cách trơn tru. Nếu bạn không đồng ý bật quyền liên quan, nó sẽ không ảnh hưởng đến việc bạn sử dụng
			các tính năng cơ bản của chúng tôi.
		</p>
		<p>3. Tương tác xã hội</p>
		<p>
			Chúng tôi cung cấp cho bạn rất nhiều tính năng tương tác xã hội. Để giúp bạn nhanh chóng làm quen với nhiều bạn bè
			hơn và giao tiếp tương tác, chúng tôi sẽ thu thập và sử dụng thông tin liên quan theo hướng dẫn dưới đây.
		</p>
		<p>
			3.1 Tương tác nội dung
		</p>
		<p>
			Khi bạn tương tác, chúng tôi thu thập và hiển thị công khai hồ sơ và nội dung tương tác của bạn (duyệt, thích,
			nhận xét, theo dõi, sưu tập, tweet lại, chia sẻ và @ người dùng khác).
		</p>
		<p>
			Trong trường hợp bạn chia sẻ hoặc nhận nội dung, thông tin hoạt động được chia sẻ, UniLive cần truy cập vào bảng
			cắt của bạn, đọc mật khẩu, mã chia sẻ, liên kết để thực hiện các dịch vụ nhảy, chia sẻ, nhận phúc lợi, liên kết
			hoạt động, giúp người dùng mở nội dung có liên quan và nâng cao trải nghiệm người dùng. Chúng tôi chỉ xác định nội
			dung clipboard cục bộ và chỉ tải nó lên máy chủ nếu nó thuộc về các chỉ thị UniLive. Ngoài ra, khi bạn chia sẻ
			hoặc lưu video, hình ảnh, chúng tôi cần đọc album của bạn.
		</p>
		<p>
			Một số thiết bị có thể nhắc rằng ứng dụng đang đọc bảng cắt hoặc album của bạn, nhưng các hệ thống này không phân
			biệt được liệu nó được xử lý cục bộ hay tải lên và có khả năng báo động sai do sự khác biệt trong các tiêu chuẩn
			thống kê thiết bị khác nhau. Nếu bạn có bất kỳ câu hỏi liên quan, xin vui lòng liên hệ với chúng tôi.
		</p>
		<p>
			3.2 Thông báo tin nhắn
		</p>
		<p>
			Bạn biết và đồng ý rằng UniLive có thể cung cấp cho bạn thông tin liên quan đến nội dung, hoạt động, dịch vụ, chức
			năng mà bạn có thể quan tâm và/hoặc cần thiết để giúp bạn xác minh danh tính, xác minh bảo mật và nghiên cứu trải
			nghiệm sử dụng người dùng thông qua tin nhắn văn bản, điện thoại, email và thông báo hệ thống dựa trên số điện
			thoại di động, hộp thư mà bạn cho phép thu thập. Nếu bạn không muốn chấp nhận thông tin đẩy có liên quan, bạn có
			thể thiết lập thông qua đường dẫn được cung cấp bởi "Quyền của bạn" trong Phần IV của Chính sách Bảo mật này.
		</p>
		<p>4. Sản xuất&xuất bản</p>
		<p>
			4.1 Chụp ảnh, sản xuất
		</p>
		<p>
			Khi bạn sử dụng video ngắn, công cụ phát trực tiếp để quay, tạo video âm thanh, nội dung hình ảnh, chúng tôi sẽ
			yêu cầu bạn cho phép bật máy ảnh để quay, ghi, quyền album/lưu trữ (bao gồm thẻ SD) để thực hiện nội dung tải lên
			album và lưu trữ đồng bộ cục bộ để ngăn chặn mất tác phẩm, quyền micrô để ghi âm thanh. Xin ông biết rằng chúng
			tôi chỉ tải lên video âm thanh, nội dung hình ảnh mà ông chọn tải lên, chứ không tải lên thông tin khác. Đồng
			thời, để theo dõi ánh sáng, điều chỉnh độ sáng, để đạt được điều chỉnh góc của khung hình chụp, chúng tôi sẽ gọi
			cảm biến ánh sáng, tăng tốc cảm biến cho thiết bị của bạn. Trong quá trình này, bạn có thể chọn cung cấp cho chúng
			tôi toàn bộ hoặc một phần giấy phép. Nếu bạn không đồng ý bật quyền liên quan, nó sẽ không ảnh hưởng đến việc bạn
			sử dụng các tính năng cơ bản của chúng tôi.
		</p>
		<p>
			4.2 Giải phóng
		</p>
		<p>
			Khi bạn xuất bản công khai nội dung văn bản, video, chúng tôi sẽ thu thập nội dung liên quan và hiển thị công khai
			hình đại diện, biệt danh và thông tin trên.
		</p>
		<p>
			4.2.1 Tải lên nhanh: Để tăng tốc độ tải lên tác phẩm được xuất bản, làm phong phú thêm khả năng xuất bản, tối ưu
			hóa trải nghiệm và tiết kiệm lưu lượng truy cập, trước khi bạn nhấp vào nút "Xuất bản" sau khi chụp xong, chúng
			tôi sẽ bắt đầu tải lên và lưu nội dung đó tạm thời vào máy chủ của chúng tôi với thiết bị của bạn được kết nối
			WLAN. Nhưng xin ông biết rằng chức năng này sẽ không phát hành các tác phẩm này trước thời hạn, chỉ khi ông nhấn
			nút "Phát hành" mới có thể gửi phát hành. Nếu bạn hủy đăng hoặc không tải lên thành công vì lý do khác, chúng tôi
			sẽ xóa nội dung đó ngay lập tức. Bạn có thể tắt tính năng này thông qua đường dẫn được cung cấp trong Phần IV của
			Chính sách bảo mật này, Quyền của bạn.
		</p>
		<p>5. Giới thiệu cá nhân</p>
		<p>
			5.1 Dịch vụ tư vấn cá nhân
			Để hiển thị cho bạn, đề xuất nội dung có liên quan hơn và cung cấp các dịch vụ phù hợp hơn với yêu cầu của bạn,
			chúng tôi thu thập và sử dụng lịch sử duyệt web, phát lại, tìm kiếm của bạn trong quá trình sử dụng Dịch vụ Nền
			tảng UniLive và kết hợp với thông tin thiết bị được thu thập theo quy định của pháp luật (OAID, AndroidID, IDFA),
			thông tin nhật ký và các thông tin khác mà bạn ủy quyền để gửi cho bạn các trang, video, nội dung trực tiếp và kết
			quả tìm kiếm mà bạn có thể quan tâm, bạn bè có thể biết, quảng cáo thương mại, dịch vụ, tính năng dựa trên sở
			thích của bạn. Nếu bạn cho phép chúng tôi cá nhân hóa các đề xuất trên trang chủ, trực tiếp hoặc trang trung tâm
			thương mại dựa trên thông tin vị trí của bạn, chúng tôi cũng thu thập thông tin vị trí của bạn trong các tính năng
			tương ứng để cá nhân hóa các đề xuất.
		</p>
		<p>
			5.2 Cơ chế hoạt động được đề xuất cá nhân
		</p>
		<p>
			Các đề xuất được cá nhân hóa dự đoán các đặc điểm ưa thích của bạn dựa trên mô hình thuật toán, phù hợp với nội
			dung, dịch vụ hoặc thông tin khác mà bạn có thể quan tâm, sắp xếp các thông tin đó được hiển thị cho bạn. Chúng
			tôi sẽ căn cứ vào hồ sơ truy cập internet cá nhân của bạn trong quá trình sử dụng dịch vụ nền tảng UniLive, tiến
			hành phản hồi thời gian thực đối với mô hình đề xuất, không ngừng điều chỉnh kết quả đề xuất tối ưu hóa. Để đáp
			ứng nhu cầu đa dạng của bạn, chúng tôi sẽ giới thiệu công nghệ đề xuất đa dạng hóa trong quá trình
			sắp xếp, mở rộng nội dung đề xuất, tránh tập trung quá mức nội dung cùng loại.
		</p>
		<p>6. Dịch vụ khách hàng</p>
		<p>
			Để cung cấp cho bạn dịch vụ khách hàng, chúng tôi có thể yêu cầu bạn cung cấp thông tin cá nhân cần thiết để xác
			thực để giữ an toàn cho tài khoản của bạn. Để tạo điều kiện liên lạc với bạn, giúp bạn giải quyết vấn đề càng sớm
			càng tốt hoặc ghi lại các giải pháp và kết quả của các vấn đề liên quan, chúng tôi có thể thu thập các thông tin
			liên quan sau: chi tiết liên lạc (số điện thoại/email bạn sử dụng khi liên hệ với chúng tôi hoặc các chi tiết liên
			lạc khác mà bạn chủ động cung cấp cho chúng tôi), thông tin liên lạc của bạn (văn bản/hình ảnh/video/cuộc gọi của
			bạn với chúng tôi hoặc với những người có liên quan) và các thông tin cần thiết khác (thông tin tài khoản, thông
			tin đặt hàng, thông tin khác mà bạn cung cấp để chứng minh các sự kiện liên quan).
		</p>
		<p>
			Để cung cấp dịch vụ và nhu cầu hợp lý để cải thiện chất lượng dịch vụ, chúng tôi cũng có thể sử dụng thông tin
			liên quan đến bạn: thông tin bạn cung cấp khi liên hệ với dịch vụ khách hàng, thông tin trả lời câu hỏi bạn gửi
			cho chúng tôi khi tham gia khảo sát.
		</p>
		<p>7. Thiết bị vớt váng dầu mỡ cho xử lý nước thải -PetroXtractor - Well Oil Skimmer </p>
		<p>
			7.1 Để cung cấp cho bạn nội dung, dịch vụ chất lượng và đáng tin cậy, duy trì hoạt động bình thường và ổn định của
			sản phẩm và chức năng, ngăn chặn tốt hơn các chương trình độc hại, trang web lừa đảo, lỗ hổng mạng, virus máy
			tính, tấn công mạng, rủi ro an ninh xâm nhập mạng, xác định chính xác hơn các vi phạm pháp luật và quy định hoặc
			các quy tắc liên quan đến nền tảng UniLive bằng cách chống gian lận, chống gian lận để bảo vệ an toàn và lợi ích
			hợp pháp của bạn hoặc của người dùng hoặc công chúng, chúng tôi cần thu thập, sử dụng và tích hợp thông tin thiết
			bị, thông tin nhật ký, thông tin cảm biến và các thông tin khác mà bạn ủy quyền (thông tin thu thập được từ các
			đối tác sau khi được sự ủy quyền của bạn theo pháp luật), được sử dụng để đánh giá an ninh tài khoản, an toàn giao
			dịch, xác minh danh tính, vi phạm, phát hiện và phòng ngừa sự cố an ninh và Áp dụng ghi chép, phân tích cần thiết
			theo quy định của pháp luật, Biện pháp xử lý. Chúng tôi chỉ truy cập thông tin ứng dụng đã cài đặt của bạn (tên
			gói) hoặc thông tin quá trình đang chạy, hoạt động tổng thể của ứng dụng, mức sử dụng và tần suất, sự cố ứng dụng,
			mức sử dụng cài đặt tổng thể, dữ liệu hiệu suất, nguồn ứng dụng và chỉ tải lên thông tin tên gói rất giống với ứng
			dụng khi hành vi của người dùng kích hoạt điều kiện kiểm soát gió.
		</p>
		<p>
			Thông tin thiết bị: Thông tin nhận dạng thiết bị (AndroidID, IDFA, IDFV, UAID (China Mobile UAID, China Unicom
			UAID và China Telecom UAID, chỉ dành cho thiết bị Android), OAID, Openudid và các thông số thiết bị tích hợp khác
			và thông tin hệ thống định danh thiết bị, các định danh khác nhau sẽ khác nhau về ngày hết hạn, cho dù nó có thể
			được thiết lập lại bởi người dùng và cách lấy và các hệ thống khác nhau), thông tin liên quan đến mạng (Bluetooth,
			địa chỉ IP, nhà điều hành mạng, trạng thái mạng, loại, cách truy cập, dữ liệu chất lượng mạng), thông tin tham số
			thiết bị (tên thiết bị, mô hình thiết bị), thông tin hệ điều hành phần mềm và phần cứng (phiên bản ứng dụng này,
			hệ điều hành, ngôn ngữ, độ phân giải).
		</p>
		<p>
			Thông tin nhật ký: hoạt động, sử dụng các bản ghi Internet cá nhân (duyệt web, chơi, tìm kiếm, thích, theo dõi,
			sưu tầm, bình luận, tải xuống, chia sẻ, giao dịch).
		</p>
		<p>
			Vui lòng hiểu rằng khi bạn chuyển UniLive sang chạy nền trên thiết bị của mình, hành vi thu thập thông tin mà
			trước đây bạn đã sử dụng các tính năng liên quan đến UniLive có thể không được dừng lại ngay lập tức do một số mô
			hình thiết bị và lý do hệ thống, dẫn đến hành vi thu thập thông tin nền ngắn.
		</p>
		<p>
			7.2 Khi tiến hành nghiên cứu, phân tích thống kê để nâng cao chất lượng và hiệu quả hoạt động, chúng tôi xử lý
			thông tin cá nhân nói trên và không liên quan đến danh tính thực của bạn. Thông tin phân biệt được mô tả ở trên sẽ
			được sử dụng để cải thiện nội dung và bố cục của Nền tảng UniLive, cung cấp hỗ trợ sản phẩm hoặc dịch vụ cho các
			quyết định kinh doanh và cải thiện các sản phẩm và dịch vụ mà chúng tôi cung cấp trên Nền tảng.
		</p>
		<p>8. Thu thập thông tin cá nhân gián tiếp từ bên thứ ba.</p>
		<p>
			Chúng tôi không chủ động thu thập thông tin cá nhân của bạn từ bên thứ ba. Nếu thông tin cá nhân của bạn được yêu
			cầu từ bên thứ ba để thực hiện các chức năng và dịch vụ liên quan, chúng tôi xác nhận rằng bên thứ ba đã được bạn
			ủy quyền cho thông tin cá nhân liên quan hoặc bên thứ ba được cấp phép hoặc yêu cầu tiết lộ thông tin cá nhân của
			bạn cho chúng tôi theo luật. Nếu chúng tôi thực hiện các hoạt động xử lý thông tin cá nhân cần thiết để thực hiện
			các dịch vụ vượt quá thẩm quyền của bạn khi ban đầu bạn cung cấp thông tin cá nhân cho bên thứ ba, chúng tôi sẽ có
			sự đồng ý rõ ràng của bạn.
		</p>
		<p>9. Cookie và các công nghệ tương tự</p>
		<p>
			Cookie và công nghệ nhận dạng thông tin thiết bị tương tự là những công nghệ được sử dụng phổ biến trên Internet.
			Khi bạn sử dụng Dịch vụ của chúng tôi, chúng tôi có thể sử dụng các công nghệ liên quan để gửi một hoặc nhiều
			cookie hoặc số nhận dạng ẩn danh (sau đây gọi là "cookie") đến thiết bị của bạn để thu thập, xác định thông tin
			khi bạn sử dụng Dịch vụ Nền tảng UniLive.
			Cookie được sử dụng chủ yếu để đảm bảo hoạt động an toàn và hiệu quả của các chức năng và dịch vụ của sản phẩm,
			cho phép chúng tôi xác nhận trạng thái an toàn của tài khoản và giao dịch của bạn, để kiểm tra các trường hợp bất
			thường liên quan đến sự cố và chậm trễ; Giúp bạn tiết kiệm các bước và quy trình liên tục điền vào biểu mẫu, nhập
			nội dung tìm kiếm để có trải nghiệm sử dụng dễ dàng hơn; Bên cạnh đó, chúng tôi còn có thể sử dụng các
			công nghệ trên để giới thiệu, trình bày, đưa ra nội dung mà bạn quan tâm, tối ưu hóa sự lựa chọn và
			tương tác của bạn về nội dung. Chúng tôi cam kết sẽ không sử dụng cookie cho bất kỳ mục đích nào khác ngoài
			các mục đích được mô tả trong Chính sách bảo mật này.
			Bạn có thể quản lý hoặc xóa cookie theo sở thích của mình. Hầu hết các trình duyệt cung cấp cho người dùng khả
			năng xóa dữ liệu bộ nhớ cache của trình duyệt, bạn có thể hoạt động trong cài đặt trình duyệt để xóa dữ liệu
			cookie và cộng với bạn có thể xóa tất cả các cookie được lưu trong phần mềm. Tuy nhiên, các tính năng hoặc dịch vụ
			do chúng tôi cung cấp và phụ thuộc vào cookie có thể không được sử dụng sau khi xóa.
		</p>
		<p>10. Công nghệ SDK và API của bên thứ ba</p>
		<p>
			Một số tính năng và/hoặc dịch vụ yêu cầu sử dụng công nghệ SDK (hoặc API) của bên thứ ba. Xin cho biết các bên thứ
			ba này
			SDK (hoặc API) có thể thu thập hoặc sử dụng một số thông tin cá nhân của bạn. Chúng tôi tiến hành kiểm tra bảo mật
			nghiêm ngặt SDK (hoặc API) có liên quan và sử dụng các phương tiện kỹ thuật để cố gắng đảm bảo rằng thông tin cá
			nhân được xử lý phù hợp với Chính sách bảo mật này và bất kỳ biện pháp bảo mật và bảo mật nào khác có liên quan.
			Ngoài ra, đối với các công ty, tổ chức và cá nhân mà chúng tôi chia sẻ thông tin người dùng, chúng tôi cũng ký
			thỏa thuận với họ để điều chỉnh việc sử dụng thông tin người dùng.
		</p>
		<p>11. Ngoại lệ với sự đồng ý</p>
		<p>
			Theo luật và quy định, việc xử lý thông tin cá nhân của bạn không cần sự đồng ý của bạn trong các trường hợp
			sau:<br />
			(1) cần thiết để ký kết, thực hiện hợp đồng của bạn với tư cách là một bên hoặc để thực hiện quản lý nguồn nhân
			lực phù hợp với các quy tắc và quy định lao động được thiết lập theo pháp luật và các hợp đồng tập thể được ký kết
			theo pháp luật; br />
			(2) cần thiết để thực hiện nghĩa vụ pháp lý hoặc nghĩa vụ pháp lý; br />
			(3) cần thiết để ứng phó với các trường hợp khẩn cấp về sức khỏe cộng đồng hoặc trong trường hợp khẩn cấp để bảo
			vệ sức khỏe tính mạng và an toàn tài sản của các thể nhân; br />
			(4) thực hiện các hành vi đưa tin, giám sát dư luận vì lợi ích công cộng và xử lý thông tin cá nhân của bạn trong
			phạm vi hợp lý; br />
			5- Các trường hợp khác theo quy định của pháp luật, pháp quy hành chính.
		</p>
		<h3>Chia sẻ, chuyển giao và công khai thông tin cá nhân</h3>
		<p>
			Chúng tôi sẽ không chia sẻ thông tin cá nhân của bạn với các bên thứ ba trừ khi, để thực hiện các mục đích được
			nêu trong Chính sách bảo mật này, chúng tôi cần chia sẻ thông tin cá nhân của bạn với các công ty liên kết hoặc
			đối tác bên thứ ba (nhà cung cấp dịch vụ, nhà khai thác mạng, nhà sản xuất thiết bị và các đối tác khác) trong các
			trường hợp sau. Chúng tôi sẽ tuân thủ các nguyên tắc hợp pháp, chính đáng và cần thiết để đánh giá thận trọng mục
			đích xử lý, khả năng đảm bảo an toàn của đối tác và ký các thỏa thuận liên quan theo quy định của pháp luật. Đồng
			thời, chúng tôi sẽ tiến hành giám sát và quản lý nghiêm ngặt đối tác, một khi phát hiện họ có hành vi vi phạm hợp
			đồng, sẽ lập tức ngừng hợp tác và truy cứu trách nhiệm pháp lý của họ.
		</p>
		<p>Cảnh hợp tác liên quan như sau:</p>
		<p>1. Để thực hiện các chức năng và dịch vụ liên quan.</p>
		<p>1.1 Đăng nhập và dịch vụ xã hội: Để cung cấp cho bạn các dịch vụ đăng ký, đăng nhập và chia sẻ của bên thứ ba,
			các đối tác đăng nhập và dịch vụ xã hội của chúng tôi (hộp thư) có thể sử dụng thông tin thiết bị của bạn (OAID,
			AndroidID, IDFA).</p>
		<p>1.2 Các thương gia bên thứ ba của Nền tảng: Để giúp bạn mua hàng hóa/dịch vụ tại các thương gia bên thứ ba, thực
			hiện các cuộc hẹn cho hàng hóa/dịch vụ, hiển thị thông tin giảm giá tùy chỉnh cho các thương gia bên thứ ba hoặc
			cung cấp cho bạn dịch vụ hậu mãi, chúng tôi chia sẻ đơn đặt hàng và thông tin liên quan đến giao dịch và/hoặc
			thông tin nhận dạng tài khoản của bạn với các thương gia bên thứ ba của Nền tảng.</p>
		<p>1.3 Dịch vụ thanh toán: Để đảm bảo giao dịch an toàn và hoàn thành thanh toán hàng hóa/dịch vụ, các tổ chức thanh
			toán bên thứ ba mà chúng tôi hợp tác có thể cần xử lý thông tin đặt hàng, thông tin thiết bị của bạn (OAID,
			AndroidID, IDFA).</p>
		<p>1.4 Dịch vụ hậu cần: Để hoàn thành việc giao hàng và giao hàng chính xác và an toàn cho bạn (hàng hóa bạn mua
			hoặc tham gia hoạt động nhận), các công ty hậu cần bên thứ ba chắc chắn sẽ biết được thông tin giao hàng liên quan
			của bạn trong quá trình giao hàng và giao hàng.</p>
		<p>1.5 Thông báo tin nhắn: Để phù hợp với chức năng đẩy tin nhắn với thiết bị bạn đang sử dụng, nhà sản xuất thiết
			bị (Huawei, Xiaomi, OPPO, VIVO) sử dụng mô hình thiết bị, phiên bản và thông tin thiết bị liên quan (OAID,
			Android, IDFA).</p>
		<p>1.6 Dịch vụ khách hàng: Để xử lý kịp thời các khiếu nại, đề xuất và các yêu cầu khác của bạn, nhà cung cấp dịch
			vụ
			khách hàng cần sử dụng tài khoản và thông tin liên quan của bạn để hiểu, xử lý và đáp ứng nhu cầu của bạn. Bạn
			cũng
			có thể cần phải sử dụng số điện thoại di động của bạn hoặc liên lạc với bạn thông qua các phương tiện liên lạc
			khác
			nếu cần.</p>
		<p>2. Quảng cáo</p>
		<p>
			Dựa trên mô hình kinh doanh của chúng tôi, chúng tôi tiến hành quảng cáo và phân phối. Để đạt được mục đích đẩy và
			phân phối quảng cáo và giúp chúng tôi đánh giá, phân tích, tối ưu hóa và đo lường hiệu quả, hiệu quả của quảng
			cáo, chúng tôi có thể cần chia sẻ một số thông tin và dữ liệu của bạn với nhà quảng cáo, nhà cung cấp dịch vụ và
			đối tác bên thứ ba của nhà cung cấp (gọi chung là "đối tác quảng cáo"). Chúng tôi sử dụng các biện pháp kỹ thuật
			để xác định, ẩn danh hoặc mã hóa dữ liệu.
		</p>
		<p>
			2.1 Đẩy và phân phối quảng cáo: Khi chúng tôi đẩy và phân phối quảng cáo, các đối tác quảng cáo có thể được yêu
			cầu sử dụng thông tin thiết bị đã được xác định hoặc ẩn danh (OAID, AndroidID, IDFA), thông tin gắn thẻ hoặc thông
			tin thiết bị đã được xác định hoặc ẩn danh (OAID, AndroidID, IDFA), thông tin gắn thẻ từ các đối tác quảng cáo
			(nhà điều hành) của chúng tôi và có thể kết hợp với các dữ liệu khác được thu thập hợp pháp để tối ưu hóa và nâng
			cao khả năng tiếp cận hiệu quả giữa đẩy và phân phối quảng cáo.
		</p>
		<p>
			2.2 Phân tích thống kê quảng cáo: Chúng tôi có thể chia sẻ thông tin thiết bị bị vô danh hoặc ẩn danh của bạn
			(OAID, AndroidID, IDFA), thông tin nhãn mác, thông tin nhật ký (nhấp chuột quảng cáo, duyệt, hiển thị), dữ liệu
			chuyển đổi hiệu quả quảng cáo cho các đối tác quảng cáo cung cấp dịch vụ phân tích và tối ưu hóa thống kê quảng
			cáo để đánh giá, phân tích, giám sát và đo lường hiệu quả của dịch vụ đẩy và phân phối quảng cáo nhằm cung cấp nội
			dung quảng cáo phù hợp hơn với bạn; Nhà quảng cáo và/hoặc các nhà cung cấp dịch vụ được ủy quyền có thể yêu cầu
			chúng tôi chia sẻ một số hoặc tất cả thông tin được mô tả ở trên để phân tích hoạt động từ nguồn người dùng, tối
			ưu hóa chiến lược phân phối quảng cáo hoặc cải thiện sản phẩm và dịch vụ của họ.
		</p>
		<p>2.3 Thu thập và gợi ý thông tin cá nhân trong các tình huống quảng cáo: Bạn có thể phải chủ động điền thông tin
			cá nhân tương ứng (chi tiết liên lạc, địa chỉ, loại thông tin cụ thể tùy thuộc vào hiển thị trên trang) trong các
			trang đích mà quảng cáo của bên thứ ba có liên quan được đẩy và phân phối, và thông tin này có thể được thu thập
			và xử lý bởi nhà quảng cáo hoặc đối tác quảng cáo được ủy thác của họ.</p>
		<p>3. Tài liệu tập huấn An toàn an ninh thông tin </p>
		<p>
			3.1 Bảo mật sử dụng: Để đảm bảo an toàn cho tài khoản và/hoặc tài sản của bạn và cải thiện tính bảo mật của chúng
			tôi và các chi nhánh và dịch vụ đối tác của chúng tôi, chúng tôi có thể chia sẻ thông tin thiết bị cần thiết
			(OAID, AndroidID, IDFA), thông tin tài khoản, thông tin nhật ký với các đối tác bên thứ ba để đánh giá tổng hợp
			rủi ro tài khoản và giao dịch của bạn, tránh gian lận, ngăn chặn sự cố bảo mật và thực hiện các biện pháp ghi
			chép, kiểm toán và xử lý cần thiết theo quy định của pháp luật.
		</p>
		<p>3.2 Phân tích sản phẩm: Để phân tích sự ổn định và phù hợp của các sản phẩm và/hoặc dịch vụ của chúng tôi, các
			đối tác dịch vụ phân tích có thể được yêu cầu sử dụng nhật ký dịch vụ sản phẩm (sự cố sản phẩm, thông tin nhật ký
			flash), thông tin thiết bị (OAID, AndroidID, IDFA).</p>
		<p>Chuyển khoản</p>
		<p>Nếu chúng tôi cần chuyển thông tin cá nhân do sáp nhập, tách biệt, giải thể hoặc bị tuyên bố phá sản, bạn sẽ được
			thông báo về tên hoặc tên và chi tiết liên lạc của người nhận. Chúng tôi sẽ yêu cầu người nhận tiếp tục thực hiện
			các nghĩa vụ đã thỏa thuận trong Chính sách bảo mật này. Người nhận thay đổi mục đích xử lý ban đầu, phương thức
			xử lý, sẽ yêu cầu sự đồng ý của bạn một lần nữa.</p>
		<p>Công khai</p>
		<p>Chúng tôi sẽ không chủ động tiết lộ thông tin mà bạn không tự tiết lộ, trừ khi tuân theo luật pháp và quy định
			quốc
			gia hoặc có được sự đồng ý của bạn.</p>
		<p>Ngoại lệ đối với việc chia sẻ, chuyển giao và công khai thông tin cá nhân</p>
		<p>Theo luật và quy định, việc chia sẻ, chuyển giao hoặc tiết lộ thông tin cá nhân của bạn không cần phải có sự đồng
			ý của bạn khi:
			1. cần thiết để ký kết, thực hiện hợp đồng của bạn với tư cách là một bên hoặc để thực hiện quản lý nguồn nhân lực
			phù hợp với các quy tắc và quy định lao động được thiết lập theo pháp luật và các hợp đồng tập thể được ký kết
			theo pháp luật;</p>
		<p>2. cần thiết để thực hiện nghĩa vụ pháp lý hoặc nghĩa vụ pháp lý;</p>
		<p>3. cần thiết để ứng phó với các trường hợp khẩn cấp về sức khỏe cộng đồng hoặc trong trường hợp khẩn cấp để bảo
			vệ
			sức khỏe tính mạng và an toàn tài sản của một thể nhân;</p>
		<p>4. Thực hiện các hành vi đưa tin, giám sát dư luận vì lợi ích công cộng, xử lý thông tin cá nhân trong phạm vi
			hợp
			lý;</p>
		<p>Xử lý thông tin cá nhân do cá nhân tự công khai hoặc các thông tin cá nhân khác đã được công khai hợp pháp trong
			phạm vi hợp lý theo quy định của Luật Bảo vệ thông tin cá nhân;</p>
		<h3>Bảo vệ và lưu trữ thông tin cá nhân</h3>
		<p>
			Để giữ an toàn cho thông tin của bạn, chúng tôi cố gắng thực hiện các biện pháp bảo mật vật lý, điện tử và quản lý
			hợp lý (SSL, lưu trữ mã hóa thông tin, kiểm soát truy cập vào trung tâm dữ liệu) phù hợp với các tiêu chuẩn công
			nghiệp để bảo vệ thông tin của bạn khỏi bị rò rỉ, phá hủy hoặc mất mát. Chúng tôi có hệ thống quản lý bảo mật dữ
			liệu tiên tiến dựa trên dữ liệu làm cốt lõi và xoay quanh vòng đời dữ liệu để nâng cao tính bảo mật của hệ thống
			dịch vụ UniLive từ nhiều khía cạnh từ xây dựng tổ chức, thiết kế thể chế, quản lý con người, công nghệ sản phẩm và
			đánh giá tác động bảo vệ thông tin cá nhân.
		</p>
		<p>Chúng tôi đã thiết lập bộ phận bảo mật chuyên dụng, hệ thống quản lý an ninh, quy trình bảo mật dữ liệu để đảm
			bảo an toàn thông tin cá nhân của bạn. Chúng tôi áp dụng một hệ thống truy cập và sử dụng dữ liệu nghiêm ngặt để
			đảm bảo rằng chỉ những người được ủy quyền mới có quyền truy cập vào thông tin cá nhân của bạn. Chúng tôi thường
			xuyên giáo dục và đào tạo nhân viên về bảo mật và tiến hành kiểm toán bảo mật dữ liệu và công nghệ kịp thời.</p>
		<p>
			Đồng thời, chúng tôi cũng thành lập Trung tâm ứng phó khẩn cấp an ninh UniLive, được duy trì bởi đội ngũ kỹ thuật
			và vận hành an ninh chuyên nghiệp, để phản ứng kịp thời và hiệu quả, xử lý các lỗ hổng an ninh và các trường hợp
			bất ngờ, kết hợp với các cơ quan liên quan để tìm nguồn gốc và tấn công các sự cố an ninh. Khi sự cố bảo mật thông
			tin cá nhân xảy ra, chúng tôi sẽ thông báo cho bạn theo yêu cầu của pháp luật và quy định: các tình huống cơ bản
			và tác động có thể xảy ra của sự cố bảo mật, các biện pháp xử lý mà chúng tôi đã thực hiện hoặc sẽ thực hiện, các
			khuyến nghị mà bạn có thể tự bảo vệ và giảm thiểu rủi ro, các biện pháp khắc phục cho bạn. Tình huống liên quan
			đến sự kiện chúng tôi sẽ thông báo cho bạn bằng email, thư từ, điện thoại, thông báo đẩy. Khi gặp khó khăn trong
			việc thông báo từng đối tượng thông tin cá nhân, chúng tôi sẽ đưa ra thông báo một cách hợp lý và hiệu quả. Bên
			cạnh đó, chúng tôi còn sẽ báo cáo tình hình xử lý các sự kiện an ninh như vi phạm dữ liệu, v. v.
			theo yêu cầu của cơ quan giám sát và quản lý hữu quan.
		</p>
		<p>
			Khi sử dụng một số dịch vụ nhất định, bạn không thể tránh khỏi việc tiết lộ thông tin cá nhân của mình (điện
			thoại, thông tin tài khoản ngân hàng) cho người giao dịch hoặc người giao dịch tiềm năng. Vui lòng bảo vệ thông
			tin cá nhân của bạn đúng cách, chỉ cung cấp cho người khác khi cần thiết và không dễ dàng giao dịch thông qua các
			sản phẩm hoặc dịch vụ của bên thứ ba để tránh thông tin bị đánh cắp hoặc thậm chí bị lừa đảo qua mạng viễn thông.
		</p>
		<p>1. Nơi lưu trữ</p>
		<p>UniLive lưu trữ thông tin cá nhân được thu thập và tạo ra trong quá trình hoạt động ở Trung Quốc, theo quy định
			của
			pháp luật và quy định. Nếu địa điểm lưu trữ thông tin cá nhân của bạn được chuyển ra ngoài lãnh thổ Trung Quốc,
			chúng tôi sẽ thực hiện hoặc có được sự đồng ý riêng của bạn theo đúng quy định của pháp luật.</p>
		<p>2. Thời hạn lưu trữ</p>
		<p>Nói chung, chúng tôi chỉ lưu giữ thông tin cá nhân của bạn trong khoảng thời gian cần thiết để thực hiện mục đích
			và
			trong thời gian được quy định bởi luật pháp và quy định, sau khi vượt quá thời gian lưu trữ nêu trên, chúng tôi sẽ
			xóa hoặc ẩn danh thông tin cá nhân của bạn.</p>
		<p>Trong trường hợp chấm dứt dịch vụ hoặc hoạt động của nền tảng UniLive, chúng tôi sẽ thông báo trước cho bạn về
			việc ngừng hoạt động dưới dạng giao hàng hoặc thông báo và xóa thông tin cá nhân của bạn hoặc xử lý ẩn danh trong
			một khoảng thời gian hợp lý sau khi chấm dứt dịch vụ hoặc hoạt động.</p>
		<h3>Quyền của bạn</h3>
		<p>
			Chúng tôi đánh giá cao các quyền của bạn với tư cách là chủ thể của thông tin cá nhân và cố gắng hết sức để bảo vệ
			quyền kiểm soát thông tin cá nhân của bạn. Sau khi hoàn thành việc đăng ký và đăng nhập tài khoản UniLive, bạn có
			thể thực hiện các quyền của mình theo các cách sau (đường dẫn thực hiện quyền của từng sản phẩm liên kết có thể
			không nhất quán, tùy theo tình huống thực tế), và chúng tôi sẽ đáp ứng yêu cầu của bạn khi tuân thủ các yêu cầu
			pháp lý và quy định. Bạn cũng có thể thực hiện các quyền liên quan của mình bằng cách liên hệ với chúng tôi theo
			cách được nêu trong "Cách liên hệ với chúng tôi" trong Chính sách bảo mật này.
		</p>
		<p>Xem, sao chép, chuyển giao thông tin cá nhân</p>
		<p>Bạn có thể tham khảo, sao chép, chuyển giao thông tin cá nhân thông qua UniLive:</p>
		<p>Khi bạn thấy rằng thông tin cá nhân mà chúng tôi xử lý về bạn không chính xác hoặc không đầy đủ, sau khi xác minh
			danh tính của bạn, bạn có thể gửi yêu cầu sửa chữa hoặc bổ sung của mình cho chúng tôi bằng cách phản hồi và báo
			lỗi.</p>
		<p>Nói chung, bạn có thể chỉnh sửa, bổ sung thông tin bạn gửi bất cứ lúc nào, nhưng một số thông tin đăng ký ban đầu
			và
			thông tin xác minh được cung cấp khi đăng ký không nằm trong số những thông tin có thể thay đổi vì lý do bảo mật
			và
			xác minh danh tính (dịch vụ khiếu nại qua email).</p>
		<p>Xóa thông tin cá nhân</p>
		<p>Bạn có thể xóa một số thông tin của mình theo đường dẫn được liệt kê ở trên (ii) để sửa, bổ sung thông tin cá
			nhân hoặc yêu cầu hủy tài khoản để xóa toàn bộ thông tin của bạn.</p>
		<p>Bạn cũng có thể yêu cầu chúng tôi xóa thông tin cá nhân trong các trường hợp sau:</p>
		<p>1. Mục đích xử lý đã đạt được, không thể đạt được hoặc không còn cần thiết để đạt được mục đích xử lý;</p>
		<p>2. Ngừng cung cấp sản phẩm hoặc dịch vụ hoặc thời hạn bảo quản đã hết hạn;</p>
		<p>3. Bạn rút lại sự đồng ý;</p>
		<p>4. Xử lý thông tin cá nhân vi phạm pháp luật hoặc vi phạm thỏa thuận;</p>
		<p>5. Các trường hợp khác theo quy định của pháp luật.</p>
		<p>Khi bạn xóa thông tin khỏi Dịch vụ của chúng tôi, chúng tôi có thể không xóa thông tin tương ứng khỏi hệ thống
			sao
			lưu ngay lập tức do các hạn chế pháp lý và kỹ thuật bảo mật hiện hành và chúng tôi sẽ ngừng xử lý ngoài việc lưu
			trữ và thực hiện các biện pháp bảo vệ an ninh cần thiết cho đến khi bản sao lưu có thể được xóa hoặc ẩn danh.</p>
		<p>Rút lại sự đồng ý và quyền thay đổi</p>
		<p>
			Vui lòng hiểu rằng mỗi tính năng và/hoặc dịch vụ yêu cầu một số thông tin cá nhân cơ bản để hoàn thành và khi bạn
			rút lại sự đồng ý của mình, chúng tôi không thể tiếp tục cung cấp cho bạn các tính năng và/hoặc dịch vụ tương ứng
			với việc rút lại sự đồng ý và không xử lý thông tin cá nhân tương ứng của bạn nữa. Tuy nhiên, quyết định rút lại
			sự đồng ý của bạn không ảnh hưởng đến hiệu lực của các hoạt động xử lý thông tin cá nhân đã được thực hiện trước
			khi rút dựa trên sự đồng ý của cá nhân đó.
		</p>
		<p>
			Bạn có thể xóa tài khoản của mình bằng cách yêu cầu. Để đảm bảo rằng người dùng tương ứng hoặc chủ thể được ủy
			quyền của người dùng đó yêu cầu hủy tài khoản, UniLive sẽ thu thập và xác minh thông tin nhận dạng của người dùng
			và người nộp đơn, cố gắng hết sức để bảo vệ quyền và lợi ích của người dùng. UniLive tuân thủ nghiêm ngặt các quy
			định của pháp luật liên quan đến việc bảo vệ thông tin cá nhân và thông tin trên chỉ được sử dụng để hủy tài khoản
			này.
		</p>
		<p>UniLive đặc biệt nhắc nhở rằng việc đăng xuất khỏi tài khoản của bạn là không thể đảo ngược. Trừ khi có quy định
			khác của pháp luật và quy định, chúng tôi sẽ xóa hoặc ẩn danh thông tin cá nhân về bạn sau khi bạn đăng xuất khỏi
			tài khoản của mình và nếu việc xóa thông tin cá nhân khó thực hiện về mặt kỹ thuật, chúng tôi sẽ ngừng xử lý ngoài
			việc lưu trữ và thực hiện các biện pháp bảo vệ an ninh cần thiết.</p>
		<p>
			Trong trường hợp người dùng UniLive không may qua đời, tài khoản chưa đăng xuất có thể trở thành tài khoản tưởng
			niệm sau khi người dùng qua đời, UniLive sẽ tiếp tục bảo vệ an toàn thông tin cá nhân của người dùng trong tài
			khoản tưởng niệm. Người thân của người quá cố có thể liên hệ với chúng tôi thông qua "Cách liên hệ với chúng tôi"
			trong Chính sách bảo mật này, với điều kiện không vi phạm sự sắp xếp của người quá cố khi còn sống và không xâm
			phạm lợi ích chính đáng của người khác và công chúng, sau khi hoàn thành thủ tục xác minh danh tính, họ có thể
			truy cập, sao chép, chỉnh sửa, xóa thông tin cá nhân của người quá cố để thực hiện các quyền và lợi ích hợp pháp
			và chính đáng theo quy định của pháp luật.
		</p>
		<h3>Bảo vệ trẻ vị thành niên</h3>
		<p>
			Chúng tôi bảo vệ thông tin cá nhân của trẻ vị thành niên theo các quy định của pháp luật và quy định quốc gia có
			liên quan và sẽ chỉ thu thập, sử dụng, chia sẻ, chuyển giao và tiết lộ thông tin cá nhân của trẻ vị thành niên khi
			được pháp luật cho phép, cha mẹ hoặc người giám hộ khác đồng ý rõ ràng hoặc cần thiết để bảo vệ trẻ vị thành niên.
			Thông tin liên quan sẽ bị xóa nếu chúng tôi phát hiện ra rằng thông tin cá nhân của trẻ vị thành niên đã được thu
			thập mà không có sự đồng ý trước của cha mẹ hoặc người giám hộ khác. Chúng tôi đã thiết lập các quy tắc nghiêm
			ngặt về việc sử dụng thu thập thông tin của trẻ vị thành niên để bảo vệ thông tin cá nhân của trẻ em (dưới 14
			tuổi) và thanh thiếu niên (dưới 18 tuổi).
			Nếu bạn là người giám hộ của trẻ vị thành niên, khi bạn có câu hỏi liên quan đến thông tin cá nhân của trẻ vị
			thành niên mà bạn đang giám hộ, bạn có thể liên hệ với chúng tôi theo cách nêu trong Chính sách bảo mật này "Cách
			liên hệ với chúng tôi".
		</p>
		<h3>Truy cập và cập nhật chính sách bảo mật</h3>
		<p>(i) Truy cập Chính sách bảo mật này</p>
		<p>1. Chúng tôi sẽ nhắc nhở bạn về Chính sách bảo mật này và bạn sẽ tự quyết định có đồng ý hay không khi bạn đăng
			ký tài khoản UniLive lần đầu tiên.</p>
		<p>(II) Cập nhật chính sách bảo mật nàyUniLive có thể sửa đổi Chính sách bảo mật này trong thời gian thích hợp và
			chúng tôi sẽ không cắt giảm các quyền của
			bạn theo Chính sách bảo mật này mà không có sự đồng ý rõ ràng của bạn hoặc tuân thủ các quy định của pháp luật.
		</p>
		<p>2. Sau khi cập nhật Chính sách bảo mật của UniLive, UniLive sẽ hiển thị cho bạn phiên bản mới nhất bằng thư nội
			bộ hoặc các phương tiện thích hợp khác khi bạn đăng nhập để bạn có thể cập nhật kịp thời về chính sách bảo mật
			này.</p>
		<p>3. Đối với những thay đổi lớn, chúng tôi cung cấp các thông báo đáng chú ý (hộp thư, tin nhắn riêng, thông báo hệ
			thống, nhắc nhở cửa sổ nảy).</p>
		<h3>Làm thế nào để liên hệ với chúng tôi</h3>
		<p>Chúng tôi đã thiết lập một đội ngũ bảo vệ thông tin cá nhân chuyên dụng và người chịu trách nhiệm. Nếu bạn có bất
			kỳ câu hỏi, khiếu nại, ý kiến hoặc đề xuất nào về các vấn đề liên quan đến bảo vệ thông tin cá nhân, bạn có thể
			liên hệ với chúng tôi theo bất kỳ cách nào sau đây:</p>
		<p>1. Gửi thư đến: unilive.team@gmail.com </p>
		<p>UniLive sẽ xem xét các vấn đề liên quan càng sớm càng tốt và trả lời kịp thời khi danh tính của bạn được xác
			minh.
			Nói chung, chúng tôi sẽ trả lời yêu cầu của bạn trong vòng 15 ngày làm việc.</p>





	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/PrivacyPolicyZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/PrivacyPolicyKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/PrivacyPolicyJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/PrivacyPolicyRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/PrivacyPolicyVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/PrivacyPolicyTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/PrivacyPolicyKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/PrivacyPolicyJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/PrivacyPolicyRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/PrivacyPolicyVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/PrivacyPolicyTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}


h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}
</style>